<template>
  <div>
    <v-card>
      <v-card-title>
        <div>
          <div class="headline">Opret managed rapport</div>
          <span class="subtitle-1 light-grey"
            >Rapporten vil automatisk blive oprettet til forrige måned</span
          >
        </div>
      </v-card-title>
      <v-card-text>
        <v-flex sm6>
          <v-autocomplete
            :search-input.sync="search"
            v-model="business"
            label="Virksomhed"
            :items="businesses"
            item-text="display_name"
            return-object
          ></v-autocomplete>
        </v-flex>

        <simple-rich-text-input v-model="summary"></simple-rich-text-input>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="save()">
          Opret
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import SimpleRichTextInput from "../../inputs/SimpleRichTextInput";
import { mapActions } from "vuex";
import _ from "lodash";

export default {
  components: { SimpleRichTextInput },
  data: () => ({
    businesses: [],
    business: null,
    summary: "",
    search: ""
  }),
  methods: {
    ...mapActions("moderator", ["createManagedReport"]),
    ...mapActions("core", ["getBusinesses"]),

    save() {
      const params = {
        business_id: this.business.uuid,
        summary: this.summary
      };
      this.createManagedReport(params).then(
        () => {
          this.setSnackSuccess("Oprettet");
          this.$router.push({ name: "managed_reports" });
        },
        () => {
          this.setSnackError("Fejl");
        }
      );
    },
    getAllBusinesses(params = {
        is_managed: true,
        has_subscription: true,
        page: 1,
        limit: 100
    }) {
      this.getBusinesses(params).then(
        businesses => {
          this.businesses = businesses.response;
        },
        () => {
          this.setSnackError("Fejl");
        }
      );
    }
  },
  watch: {
    search: {
       handler: _.debounce(function() {
        let params = {
          limit: 100,
          search: this.search ? this.search : " ",
          page: 1,
          is_managed: true,
          has_subscription: true
        };
        this.getAllBusinesses(params)
      }, 500)
    }
  }
};
</script>
